const mutations = {
  // 子标题
  SET_TITLE (state, data) {
    state.title = data
  },
  // 父标题
  SET_PER_TITLE (state, data) {
    state.perTitle = data
  },
  // 资讯标题
  SET_DETAIL_TITLE (state, data) {
    state.detailTile = data
  },
  SET_CART_LIST (state, data) {
    state.cartList.push(data)
  },
  // token
  SET_TOKEN (state, data) {
    state.token = data
  },
  // 刷新token
  SET_TOKEN1 (state, data) {
    state.token1 = data
  },
  // 验证码错误状态
  SET_CODE_FLAG (state, data) {
    state.codeFlag = data
  },
  // 密码错误状态
  SET_PWD_FLAG (state, data) {
    state.pwdFlag = data
  },
  // 密码错误文字状态
  SET_TEXT_FLAG (state, data) {
    state.textFlag = data
  },
  // 用户信息
  SET_USER_INFO (state, data) {
    state.userInfo = data
  },
  // 用户信息
  SET_USER_ID (state, data) {
    state.userId = data
  },
  // 用户手机号
  SET_PHONE (state, data) {
    state.phone = data
  },
  // 选中购物车
  SET_CHECK_SHOP_LIST (state, data) {
    state.checkShopList = []
    if (Object.prototype.toString.call(data) === '[object Object]') {
      state.checkShopList.push(data)
      console.log(state.checkShopList)
    } else {
      state.checkShopList = data
    }
  },
  // 记住密码
  SET_PASSWORD (state, data) {
    state.userLoginInfo = data
  },
  // 首页订单信息
  GET_ORDER_INFO (state, data) {
    state.orderInfo.orderLength = data
  },
  GET_ORDER_INFO1 (state, data) {
    state.orderInfo.payLength = data
  },
  GET_ORDER_INFO2 (state, data) {
    state.orderInfo.deliverGoodsLength = data
  },
  SET_ART_ORDER_ID (state, data) {
    state.orderId = data
  },
  CHANGE_META_INFO (state, metaInfo) {
    state.metaInfo = metaInfo
  }
}
export default mutations
