import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import VPS from 'vuex-persistedstate'
Vue.use(Vuex)
const state = {
  // 面包屑标题
  perTitle: '',
  title: '',
  cartList: [],
  token: '',
  token1: '',
  codeFlag: false,
  pwdFlag: false,
  userInfo: '',
  phone: '',
  checkShopList: [],
  // 记住密码
  userLoginInfo: {
    username: '',
    password: ''
  },
  metaInfo: {},
  textFlag: false,
  userId: '',
  orderInfo: {
    orderLength: '0',
    payLength: '0',
    deliverGoodsLength: '0'
  },
  orderId: '2023120800008',
  detailTile: ''

}
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [VPS()]

})
