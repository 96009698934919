import router from '../router'
import store from '@/store'
import { Message } from 'element-ui'
const whileList = [
  'login',
  'index',
  'about',
  'information',
  'productList',
  'productListDetail',
  'informationDetail',
  'settledPlatform',
  'shopStreet',
  'shopStreetDetail'
]
router.beforeEach((to, from, next) => {
  if (to.name === '404') {
    return next('index')
  }
  if (whileList.indexOf(to.name) !== -1) {
    return next()
  } else {
    if (store.state.token !== '') {
      return next()
    } else {
      if (to.fullPath === '/layout/account?Id=Safety') {
        return next()
      } else {
        location.href = '/#/layout/login'
        Message.error('请先登录')
        return next('login')
      }
    }
  }
})
