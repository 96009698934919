import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueMeta from 'vue-meta'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.scss'
import './utils/permission.js'

Vue.use(VueMeta)
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
Vue.use(ElementUI)
console.log(process.env.VUE_APP_BASE_API)
router.beforeEach((to, from, next) => {
  store.commit('SET_PER_TITLE', to.meta.perTitle)
  store.commit('SET_TITLE', to.meta.title)
  if (to.meta.metaInfo) {
    store.commit('CHANGE_META_INFO', to.meta.metaInfo)
  }
  document.title = to.meta.perTitle
  window.scrollTo(0, 0)
  return next()
})

new Vue({
  router,
  store,
  metaInfo () {
    return {
      meta: [
        {
          name: 'keywords',
          content: this.$store.state.metaInfo.keywords
        }, {
          name: 'description',
          content: this.$store.state.metaInfo.description
        }
      ]
    }
  },
  render: (h) => h(App)
}).$mount('#app')
